import React from "react"

// Components
import Button from "./button"

// Styles
import styles from "../styles/scss/components/imageGridCard.module.scss"

const ImageGridCard = ({ title, image, link, children }) => {
    return (
        <div className={styles.boxWrap}>
            {/* Image */}
            <div className={styles.boxWrapImage}>
                <div
                    style={{ backgroundImage: `url(${image})` }}
                    className={styles.boxImage}
                />
            </div>

            {/* Content */}
            <div className={styles.boxContent}>
                <h3 className={styles.cardTitle}>{title}</h3>
                {children}
                <Button
                    text="READ MORE"
                    url={link}
                    className={styles.button}
                    target="_blank"
                    newBlue
                    pdf
                    rel="noopener noreferrer"
                />
            </div>
        </div>
    )
}

export default React.memo(ImageGridCard)
