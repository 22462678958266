import React from "react"
import Title from "./title"
import cn from "classnames"
import styles from "../styles/scss/components/assetSection.module.scss"

const AssetSection = ({
    assets,
    background = "light",
    children,
    title,
    variant = "industrial",
}) => {
    
    const getBackgroundClassname = {
        light: styles.backgroundLight,
        dark: styles.backgroundDark,
    }
    const getContainerClassname = {
        tech: styles.techAnchors,
        industrial: styles.industrialAnchors,
    }
    return (
        <section
            className={cn(
                styles.anchorsContainer,
                getBackgroundClassname[background]
            )}
        >
            <div className={styles.anchorsContentWrapper}>
                {title && (
                    <Title value={title} className={styles.anchorsTitle} />
                )}
                <div
                    className={cn(
                        styles.anchorsContainer,
                        getContainerClassname[variant]
                    )}
                >
                    {assets.map((item, index) => {
                        return (
                            <a
                                href={item.url}
                                key={index}
                                className={styles.pdfAnchor}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/img/download.svg"
                                    alt={`Download for ${item.alt}`}
                                    className={styles.pdfAnchorIcon}
                                />
                                {item.text}
                            </a>
                        )
                    })}
                </div>
                {children && (
                    <div className={styles.anchorsSubtitle}>{children}</div>
                )}
            </div>
        </section>
    )
}

export default AssetSection
