import React from "react"
import CustomModal from "./customModal"
import cn from "classnames"
import styles from "../styles/scss/components/videoGridCard.module.scss"

// NOTE: This component honors the original component found at src/components/cardVideo.js
// In the future, when the original component reaches the master branch, please use it instead of this one.

const VideoGridCard = ({
    youtubeVideoId,
    image,
    title,
    containerClass,
    imageClass,
    titleClass,
    id,
    customThumbnail,
    is360,
    externalVideo,
}) => {
    // States
    const [openVideo, setOpenVideo] = React.useState(false)

    const handleClick = React.useCallback(() => {
        if (externalVideo && typeof window !== "undefind") {
            const newWindow = window.open(externalVideo)
            if (newWindow) {
                newWindow.focus()
            }
        } else {
            setOpenVideo(true)
        }
    }, [externalVideo])

    const backgroundImg = customThumbnail !== "" ? customThumbnail : image

    return (
        <div id={id} className={cn(styles.container, containerClass)}>
            {/* Image */}
            <div
                style={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 73.88%) , url(${backgroundImg})`,
                }}
                className={cn(styles.image, imageClass)}
            >
                {youtubeVideoId && (
                    <a href="javascript:;" onClick={handleClick}>
                        {!is360 && (
                            <div className={styles.circlePlay}>
                                <i className="fa fa-play" />
                            </div>
                        )}
                    </a>
                )}
            </div>

            {/* Title */}
            {title && (
                <p
                    className={cn(
                        titleClass || styles.defaultTitle,
                        externalVideo ? styles.titleForExternalVideo : undefined
                    )}
                >
                    {title}
                </p>
            )}

            {/* Modal */}
            {youtubeVideoId && (
                <CustomModal
                    openVideo={openVideo}
                    setOpenVideo={setOpenVideo}
                    youtubeVideoId={youtubeVideoId}
                />
            )}
        </div>
    )
}

export default React.memo(VideoGridCard)
