import React, { useState } from 'react';
import styles from '../styles/scss/components/virtualGarage.module.scss';

const VirtualGarage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <>
            <div
                className={styles.virtualGarageWrapper}
                onClick={() => setIsModalOpen(true)}>
                <div className={styles.circlePlay}>
                    <i className='fa fa-play' />
                </div>
            </div>
            {
                isModalOpen &&
                <div className={styles.virtualGarageFrame}>
                    <button className={styles.close} onClick={() => setIsModalOpen(false)}>X</button>

                    <div className={styles.virtualGarageContent}>
                        <iframe
                            frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen=""
                            src="https://experience.wd40.com/auto/"
                            title="Virtual Garage" />
                    </div>

                </div>
            }
        </>
    );
};

export default VirtualGarage;