import React from "react"
import AssetSection from "../components/assetSection"
import Button from "../components/button"
import ImageGridCard from "../components/imageGridCard"
import NewLayout from "../layout/newLayout"
import SEO from "../components/seo"
import Slider from "react-slick"
import Text from "../components/text"
import Title from "../components/title"
import VideoGridCard from "../components/videoGridCard"
import VirtualGarage from "../components/virtualGarage"
import styles from "../styles/scss/templates/techsandtrade.module.scss"
import Imgix from "react-imgix"
import { buildURL } from "react-imgix"
import { supSub } from "../services/util"

export const anchorpdfs = [
    {
        text: "Industrial Guide",
        url: "https://files.wd40.com/pdf/industrial/industrial-guide.pdf",
    },
    {
        text: "CST Guide",
        url:
            "https://files.wd40.com/pdf/WD-40-CST_Product_Guide_DIGITAL_0922.pdf",
    },
    {
        text: "Farm Guide",
        url: "https://files.wd40.com/pdf/WD-40-Farm_Product_Guide.pdf",
    },
    {
        text: "Auto Guide",
        url:
            "https://files.wd40.com/pdf/WD-40-Auto_Product_Guide_DIGITAL_0922.pdf",
    },
]

const TechAndTrade = ({ pageContext }) => {
    const pageData = pageContext.page

    // Refs
    const refSlider = React.useRef(null)

    // States
    const [currentCard, setCurrentCard] = React.useState(null)

    // Memos
    const { introductionBannerContent } = React.useMemo(() => {
        const out = {
            introductionBannerContent: null,
            introductionBannerVideo: null,
        }
        const firstSectionContent = pageData.content[0]

        if (
            firstSectionContent.__typename ===
            "DatoCMS_TextAndVideoHeaderRecord"
        ) {
            if (firstSectionContent.body) {
                out.introductionBannerContent = supSub(firstSectionContent.body)
            }
            if (firstSectionContent.video) {
                out.introductionBannerVideo = firstSectionContent.video
            }
        }

        return out
    }, [pageData])

    const {
        industryRelationshipsCards,
        resourcesArticles,
        resourcesVideos,
    } = React.useMemo(() => {
        const out = {
            industryRelationshipsCards: [],
            resourcesArticles: [],
            resourcesVideos: [],
        }

        if (pageData && pageData.content) {
            out.industryRelationshipsCards = pageData.content.filter(
                item => item.__typename === "DatoCMS_TextAndImageRecord"
            )
            setCurrentCard(out.industryRelationshipsCards[0] || null)
        }

        if (pageData) {
            const { relatedArticles, relatedVideos } = pageData
            if (relatedArticles && relatedArticles.length > 0) {
                out.resourcesArticles = relatedArticles
            }
            if (relatedVideos && relatedVideos.length > 0) {
                out.resourcesVideos = relatedVideos
            }
        }

        return out
    }, [pageData])

    // Handlers
    const handleAfterChange = React.useCallback(
        currentSlide => {
            if (industryRelationshipsCards[currentSlide]) {
                setCurrentCard(industryRelationshipsCards[currentSlide])
            }
        },
        [industryRelationshipsCards, setCurrentCard]
    )

    const handlePrev = React.useCallback(() => {
        if (refSlider && refSlider.current) {
            refSlider.current.slickPrev()
        }
    }, [])

    const handleNext = React.useCallback(() => {
        if (refSlider && refSlider.current) {
            refSlider.current.slickNext()
        }
    }, [])

    // Renders
    const hero = (
        <div className={styles.heroContainer}>
            <div className={styles.heroWrapper}>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={styles.heroVideo}
                    id="heroVideo"
                >
                    <source src={pageData.heroImage.url} type="video/mp4" />
                </video>
                <Imgix
                    src="/img/techsandtrade/ttlogo.svg"
                    htmlAttributes={{ alt: "WD-40 Techs & Trade Logo" }}
                />
                <Title
                    className={styles.heroTitle}
                    value="Welcome to the WD-40® Brand Techs & Trades Program"
                />
                <Button
                    className={styles.redButton}
                    text="Join Now"
                    url={"/techs-and-trades/contact-form"}
                />
            </div>
        </div>
    )

    const introductionBanner = (
        <>
            {introductionBannerContent && (
                <div className={styles.whiteSection}>
                    <div className={styles.whiteSection__contentWrapper}>
                        <div
                            className={
                                styles.whiteSection__contentWrapper__bodyText
                            }
                            dangerouslySetInnerHTML={{
                                __html: introductionBannerContent,
                            }}
                        />
                        <VirtualGarage />
                    </div>
                </div>
            )}
        </>
    )

    const industryRelationshipsSection = (
        <>
            {industryRelationshipsCards.length > 0 && (
                <div className={styles.industryRelationshipSection}>
                    <div className={styles.topSection}>
                        <div className={styles.topSectionTitle}>
                            INDUSTRY RELATIONSHIPS
                        </div>
                        <div className={styles.topSectionSliderContainer}>
                            <Slider
                                ref={refSlider}
                                className={styles.industryRelationshipSlider}
                                swipe={false}
                                swipeToSlide={false}
                                centerMode
                                centerPadding="0px"
                                variableWidth
                                adaptiveHeight
                                fade={false}
                                infinite
                                dots
                                speed={0}
                                focusOnSelect={false}
                                slidesToShow={3}
                                slidesToScroll={3}
                                initialSlide={0}
                                afterChange={handleAfterChange}
                                responsive={[
                                    {
                                        breakpoint: 992,
                                        settings: {
                                            initialSlide: 0,
                                            swipe: true,
                                            swipeToSlide: true,
                                            centerMode: true,
                                            centerPadding: 0,
                                            variableWidth: true,
                                            adaptiveHeight: true,
                                            fade: false,
                                            infinite: true,
                                            dots: false,
                                            focusOnSelect: false,
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                        },
                                    },
                                ]}
                            >
                                {industryRelationshipsCards.map(card => (
                                    <div
                                        key={card.id}
                                        className="slideContainer"
                                        onClick={() => setCurrentCard(card)}
                                    >
                                        {card.contentUrl ? (
                                            <a
                                                className="slide"
                                                href={card.contentUrl || "#"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Imgix
                                                    className="slideImage"
                                                    src={card.image.url}
                                                    htmlAttributes={{
                                                        alt: "Relationship",
                                                    }}
                                                />
                                            </a>
                                        ) : (
                                            <div className="slide">
                                                <Imgix
                                                    className="slideImage"
                                                    src={card.image.url}
                                                    htmlAttributes={{
                                                        alt: "Relationship",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </Slider>

                            <div className={styles.arrowsContainer}>
                                <img
                                    className={styles.arrowLeft}
                                    src="/img/icons/arrow-left.svg"
                                    draggable="false"
                                    onClick={handlePrev}
                                    alt="Arrow Left"
                                />
                                <img
                                    className={styles.arrowRight}
                                    src="/img/icons/arrow-right.svg"
                                    draggable="false"
                                    onClick={handleNext}
                                    alt="Arrow Right"
                                />
                            </div>
                        </div>
                    </div>
                    {currentCard && (
                        <div className={styles.bottomSection}>
                            <div className={styles.bottomSectionMainContent}>
                                <div className={styles.bottomSectionContent}>
                                    {currentCard.title && (
                                        <div
                                            className={
                                                styles.bottomSectionTitle
                                            }
                                        >
                                            {currentCard.title}
                                        </div>
                                    )}
                                    {currentCard.body && (
                                        <div
                                            className={
                                                styles.bottomSectionDescription
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: supSub(
                                                    currentCard.body
                                                ),
                                            }}
                                        />
                                    )}
                                </div>
                                {currentCard.secondaryimage && (
                                    <div
                                        className={
                                            styles.bottomSectionImageContainer
                                        }
                                    >
                                        <Imgix
                                            className={
                                                styles.bottomSectionImage
                                            }
                                            src={currentCard.secondaryimage.url}
                                            htmlAttributes={{
                                                alt: "Secondary Card",
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )

    const resourcesSection = (
        <>
            {(resourcesArticles.length > 0 || resourcesVideos.length > 0) && (
                <div className={styles.resourcesSectionBackground}>
                    <div className={styles.resourcesSection}>
                        <Title
                            className={styles.resourcesSectionTitle}
                            value="Resources"
                        />

                        <div className={styles.resourcesSectionGrid}>
                            {resourcesArticles.length > 0 && (
                                <>
                                    {resourcesArticles.map((item, idx) => (
                                        <ImageGridCard
                                            key={`post:${idx}`}
                                            type="image"
                                            title={`${item.title}${item.subtitle
                                                ? `: ${item.subtitle}`
                                                : ""
                                                }`}
                                            image={buildURL(
                                                item.heroImage.url,
                                                { w: 500, bri: -15, fm: "jpg" }
                                            )}
                                            link={"/article/" + item.slug}
                                        >
                                            <p>{item.teaser}</p>
                                        </ImageGridCard>
                                    ))}
                                </>
                            )}
                            {resourcesVideos.length > 0 && (
                                <>
                                    {resourcesVideos.map((item, idx) => (
                                        <VideoGridCard
                                            key={`video:${idx}`}
                                            id={`middleb-${idx}`}
                                            type="video"
                                            title={item.title}
                                            youtubeVideoId={
                                                item.video.providerUid
                                            }
                                            image={item.video.thumbnailUrl}
                                            customThumbnail={
                                                item.customThumbnail
                                                    ? item.customThumbnail.url
                                                    : ""
                                            }
                                            is360={item.is360}
                                            externalVideo={item.externalvideo}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )

    const bottomSection = (
        <>
            <div className={styles.lastSection}>
                <div className={styles.lastSection__contentWrapper}>
                    <div
                        className={styles.lastSection__contentWrapper__content}
                    >
                        <Imgix
                            src="/img/techsandtrade/ttlogo.svg"
                            htmlAttributes={{ alt: "WD-40 Techs & Trade Logo" }}
                            className={
                                styles.lastSection__contentWrapper__content__logo
                            }
                        />
                        <Text
                            className={
                                styles.lastSection__contentWrapper__content__text
                            }
                        >
                            Join the WD-40® Brand Techs & Trades Program and
                            receive access to member-only content, offers, info
                            and more designed specifically for tradespeople like
                            you.
                        </Text>
                        <Button
                            className={styles.redButton}
                            text="Join Now"
                            url={"/techs-and-trades/contact-form"}
                        />
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <NewLayout>
            {/* SEO */}
            <SEO page={pageData} />

            {/* Hero */}
            {hero}

            {/* Introduction Banner Section */}
            {introductionBanner}

            {/* Industry Relationships Section */}
            {industryRelationshipsSection}

            {/* Resources Section */}
            {resourcesSection}

            <AssetSection
                title="Product Guides"
                assets={anchorpdfs}
                background="dark"
                variant="tech"
            />

            {/* Bottom Section */}
            {bottomSection}
        </NewLayout>
    )
}

export default React.memo(TechAndTrade)
